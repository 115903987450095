import React from "react"
import styled from "styled-components"
import Grid from "../../layout/grid"
import IconColor100Nights from "../icons/color/icon-color-100-night-trial"
import IconColorDelivery from "../icons/color/icon-color-fast-delivery"
import IconColorLowestPrice from "../icons/color/icon-color-lowest-price"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const ValueProposition = () => {
  return (
    <ValuePropositionWrapper>
      <Grid cols="3" gutter="100px" justify="center" align="center">
        <ValuePropositionItem
          className={"value-proposition-item"}
          as={Link}
          to={"/cancellations-refunds/"}
        >
          <IconColor100Nights />
          <h4>100 Night In-Home Comfort Trial</h4>
          <p className="small">
            We give you 100 nights to try any one of our products risk-free.
          </p>
        </ValuePropositionItem>
        <ValuePropositionItem
          className={"value-proposition-item"}
          as={Link}
          to={"/delivery/"}
        >
          <IconColorDelivery />
          <h4>Free Shipping & Returns</h4>
          <p className="small">
            Free shipping and returns for every order within 90km of all main
            city centers.
          </p>
        </ValuePropositionItem>
        <ValuePropositionItem className={"value-proposition-item"} as={"span"}>
          <IconColorLowestPrice />
          <h4>Lowest Price Guaranteed</h4>
          <p className="small">
            We do our homework so you can rest assured and don’t have to shop
            around.
          </p>
        </ValuePropositionItem>
      </Grid>
    </ValuePropositionWrapper>
  )
}

export default ValueProposition

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const ValuePropositionItem = styled.div`
  color: ${({ theme }) => theme.bodyColor};
`

const ValuePropositionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.tusk["000"]};
  padding: 10px 0px 25px 0px;
  .icon-color {
    min-height: 70px;
    padding: 15px;
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .grid .value-proposition-item {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .grid {
      grid-gap: 40px;
    }
  }
`
