import React from "react"
import styled from "styled-components"
import SvgIcon from "../../atoms/icons/svg-icon"
import ChevronLeft from "../../atoms/icons/files/chevron-left.jsx"
import ChevronRight from "../../atoms/icons/files/chevron-right.jsx"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"
import BackgroundImage from "gatsby-background-image"
import Arrow from "./Arrow"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const DesktopSlider = ({
  setReadyToStart,
  fluid,
  next,
  prev,
  title,
  subTitle,
  buttonLabel,
  buttonLink,
}) => {
  return (
    <StyledBackground
      fluid={fluid.src}
      // onLoad={setReadyToStart(true)}
      // critical
    >
      <Overlay>
        <Arrow
          onClick={prev}
          direction="left"
          DirectionSvgComponent={ChevronLeft}
        />
        <Content>
          <h1 className="shadowed">{title}</h1>
          <p className="shadowed">{subTitle}</p>
          <Button to={buttonLink}>
            <div>{buttonLabel} </div>
            <SvgIcon SvgComponent={ArrowRight} hue="danger" shade="fontColor" />
          </Button>
        </Content>
        <Arrow
          onClick={next}
          direction="right"
          DirectionSvgComponent={ChevronRight}
        />
      </Overlay>
    </StyledBackground>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Content = styled.div`
  h1.shadowed {
    text-shadow: 0px 0px 8px black;
    color: #f0f1f3;
    font-family: Roboto;
    font-size: 40px;
    font-weight: 500;
    line-height: 43px;
    text-transform: uppercase;
    max-width: 460px;
  }
  p.shadowed {
    text-shadow: 0px 0px 5px black;
    color: #f0f1f3;
    font-family: Roboto;
    font-size: 20px;
    line-height: 24px;
    margin: 20px 0;
  }
`

export const Button = styled(Link)`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
  }

  > div {
    padding: 0 10px;
  }

  > span {
    padding: 5px;
    background: #da2e32;
  }
`

const Overlay = styled.div`
  min-height: 21vw;
  display: grid;
  grid-template-columns: 1fr 100fr 1fr;
  align-items: center;
  grid-gap: 20px;
`

const StyledBackground = styled.div`
  display: block;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
  background-image: url(${({ fluid }) => fluid});
  position: relative;
  opacity: 0.99;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default DesktopSlider
