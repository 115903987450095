import "@brainhubeu/react-carousel/lib/style.css"

import React, { useState, forwardRef } from "react"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"
import { path } from "../../../lib/util"

import BackgroundImage from "gatsby-background-image"
import Carousel from "@brainhubeu/react-carousel"

import Inner from "../../layout/pageInner/Inner"
import SvgIcon from "../../atoms/icons/svg-icon"
import LinkWrapper from "../../../utils/linkWrapper"
import ChevronRight from "../../atoms/icons/files/chevron-right.jsx"
import ChevronLeft from "../../atoms/icons/files/chevron-left.jsx"

const CarouselRow = ({
  title = "Content Hub",
  subTitle = "We write and curate the best sleeping and related articles to help you get the best night sleep of your life.",
  background = "#233548",
  data,
}) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const imgData = useStaticQuery(PLACEHOLDER)
  const categories = imgData.categories.nodes
  const maxOffset = (categories && categories.length - 1) || 1

  return (
    <Row background={background}>
      <TextWrap>
        <h2>{data.title || title}</h2>
        <h3>{data.subTitle || subTitle}</h3>
      </TextWrap>
      <StyledCarousel
        addArrowClickHandler={false}
        draggable={false}
        lazyLoad
        value={slideIndex}
        slidesPerPage={4}
        breakpoints={{
          1120: {
            slidesPerPage: 3,
            arrows: true,
          },
          900: {
            slidesPerPage: 2,
            arrows: true,
          },
          600: {
            slidesPerPage: 1,
            arrows: false,
          },
        }}
        arrowRight={
          <IconWrap
            onClick={() =>
              setSlideIndex((oldVal) =>
                oldVal + 1 >= maxOffset ? 0 : oldVal + 1
              )
            }
            hue="success"
            shade="fontColor"
            size="lg"
            SvgComponent={ChevronRight}
          />
        }
        arrowLeft={
          <IconWrap
            onClick={() =>
              setSlideIndex((oldVal) =>
                oldVal - 1 < 0 ? maxOffset : oldVal - 1
              )
            }
            iconDirection="left"
            hue="success"
            shade="fontColor"
            size="lg"
            SvgComponent={ChevronLeft}
          />
        }
      >
        {categories.map((node, i) => {
          return (
            <CategoryLink
              title={node.name}
              slug={node.slug}
              key={i}
              fluid={
                path(
                  [
                    "Image",
                    "image",
                    "imageFile",
                    "localFile",
                    "childImageSharp",
                    "fluid",
                  ],
                  node
                ) || imgData.file.childImageSharp.fluid
              }
            />
          )
        })}
      </StyledCarousel>
    </Row>
  )
}

export default CarouselRow

const StyledCarousel = styled(Carousel)`
  padding: 20px 40px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 20px 0;
  }
`

const IconWrap = styled(SvgIcon)`
  pointer-events: auto;
  border-radius: 200px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  margin: 5px;
  &:hover {
    background-color: #3e4b5c;
    transform: ${({ iconDirection }) =>
      iconDirection == "left" ? "translateX(-2px);" : "translateX(2px);"};
  }
`

const CategoryLink = forwardRef(({ fluid, title, slug = "/" }, ref) => {
  return (
    <LinkWrapper to={`/sleep-matters/category/${slug}`}>
      <StyledBackground fluid={fluid}>
        <Overlay>
          <p dangerouslySetInnerHTML={{ __html: title }} />
        </Overlay>
      </StyledBackground>
    </LinkWrapper>
  )
})

const Overlay = styled.div`
  min-height: 175px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 47.38%,
    rgba(0, 0, 0, 0.44) 100%
  );
  p {
    padding: 15px;
    color: white;
    font-family: Roboto;
    font-size: 20px;
    line-height: 24px;
  }
`

const StyledBackground = styled(BackgroundImage)`
  min-width: 250px;
  cursor: pointer;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
`

const Row = styled.div`
  background: ${({ background }) => background};
  padding: 70px 0 100px 0;
`

const TextWrap = styled(Inner)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  h2 {
    color: white;
    border-bottom: 1px solid red;
  }
  h3 {
    color: #fff;
    font-size: 16px;
    margin-top: 10px;
  }
`
const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "team-image-placeholder.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    categories: allLocalWpGraphQlCategories {
      nodes {
        name
        slug
        Image {
          image {
            sourceUrl
            mediaItemId
            modified
            imageFile {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
