import React from "react"
import styled from "styled-components"
import SvgIcon from "../../atoms/icons/svg-icon"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Arrow = ({
  onClick,
  direction,
  DirectionSvgComponent,
  mobile = false,
}) => (
  <IconWrap className={"arrow-icon " + direction} onClick={onClick}>
    <SvgIcon
      hue="success"
      shade="fontColor"
      size={mobile ? "md" : "lg"}
      SvgComponent={DirectionSvgComponent}
    />
  </IconWrap>
)

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const IconWrap = styled.div`
  background-color: #3e4b5c;
  border-radius: 200px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  margin: 20px;
  &:hover {
    transform: translateX(2px);
    background: #fd3237;
    &.left {
      transform: translateX(-2px);
    }
  }
`

export default Arrow
