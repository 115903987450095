import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import Inner from "../../layout/pageInner/Inner"
import SvgIcon from "../../atoms/icons/svg-icon"
import Search from "../../atoms/icons/files/search.jsx"
import LinkWrapper from "../../../utils/linkWrapper"

const BedFinderRow = ({ data }) => {
  return (
    <StyledBackground
      fluid={data.backgroundImage.imageFile.localFile.childImageSharp.fluid}
      style={{
        backgroundPosition: "left center",
      }}
    >
      <Inner>
        <div className="content-wrapper">
          <h2>{data.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: data.body }} />
          <Button to="/bed-finder/">
            <SvgIcon
              SvgComponent={Search}
              hue="danger"
              size="md"
              shade="fontColor"
            />
            <div>Let's find your bed</div>
          </Button>
        </div>
      </Inner>
    </StyledBackground>
  )
}

export default BedFinderRow

const Button = styled(LinkWrapper)`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
  }
  > div {
    padding: 0 15px;
    font-size: 20px;
  }
  > span {
    padding: 8px;
    background: #da2e32;
  }
`

const StyledBackground = styled(BackgroundImage)`
  border-top: 1px solid ${({ theme }) => theme.colors.tusk["020"]};
  padding: 200px 0;
  .content-wrapper {
    max-width: 50%;
  }
  h2 {
    color: #223247;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 300;
    line-height: 57px;
    margin-bottom: 20px;
    max-width: 600px;
  }
  p {
    color: #223247;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 20px;

    max-width: 600px;
  }
  @media (max-width: 1280px) {
    padding: 150px 0;
    .content-wrapper {
      max-width: 60%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding: 100px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .content-wrapper {
      max-width: 80%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .content-wrapper {
      max-width: 100%;
      a {
        width: 100%;
      }
    }
  }
`
