import React, { useState } from "react"
import styled from "styled-components"
import redCheck from "../../../images/icons/red-check.svg"
import Img from "gatsby-image"

import Inner from "../../layout/pageInner/Inner"
import SvgIcon from "../../atoms/icons/svg-icon"
import ChevronDown from "../../atoms/icons/files/chevron-down.jsx"

const AboutRow = ({ data }) => {
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(0)

  return (
    <Row>
      <Inner className="inner">
        <Grid>
          <Left>
            <StyledImg
              fluid={
                data.featuredImage.imageFile.localFile.childImageSharp.fluid
              }
            />
            <Button
              hue="ruby"
              size="xl"
              onClick={() => setOpen(!open)}
              asButton
              active={open}
            >
              <div>About Us</div>
              <SvgIcon
                SvgComponent={ChevronDown}
                hue="danger"
                size="md"
                shade="fontColor"
              />
            </Button>
          </Left>
          <Right className="right">
            <h2>{data.title}</h2>
            <h3>{data.sub_title}</h3>
            <div dangerouslySetInnerHTML={{ __html: data.body }} />
          </Right>
        </Grid>
      </Inner>
      <Reveal open={open}>
        <Inner>
          <Grid>
            <div>
              {data.tabs.map(({ title }, i) => (
                <TabItem
                  key={i}
                  onClick={() => setActive(i)}
                  className={active === i && "active"}
                >
                  {title}
                </TabItem>
              ))}
            </div>
            {data.tabs.map((tab, i) => (
              <Content
                isActive={active === i}
                key={`${i}-panel`}
                dangerouslySetInnerHTML={{ __html: tab.content }}
              />
            ))}
          </Grid>
        </Inner>
      </Reveal>
    </Row>
  )
}

export default AboutRow

const Button = styled.div`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  margin: 0 auto;
  &:hover {
    background: #ed1c24;
  }
  > div {
    padding: 0 15px;
    font-size: 20px;
    width: 100%;
  }
  > span {
    padding: 8px;
    background: #da2e32;
    svg {
      transition: 0.3s all ease-in-out;
      transform: ${({ active }) =>
        active ? "rotate(180deg)" : "rotate(0deg)"};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 100%;
    justify-content: center;
  }
`

const Content = styled.div`
  p {
    margin-bottom: 10px;
  }
  li {
    display: flex;
    margin-bottom: 10px;
  }
  ul li:before {
    /* content: "✓"; */
    content: url(${redCheck});
    color: red;
    display: block;
    font-size: 23px;
    margin-right: 15px;
  }

  opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  transform: ${({ isActive }) =>
    isActive ? "translateX(0)" : "translateX(-50px)"};
  display: ${({ isActive }) => (isActive ? "block" : "none")};

  animation: slidein 0.2s ease-in-out;
  @keyframes slidein {
    from {
      transform: translateX(100px);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`

const TabItem = styled.div`
  padding: 10px;
  border-radius: 4px;
  background: #213046;
  text-align: right;
  color: #b0b4ba;
  font-size: 20px;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  margin: 5px 28% 0 5px;
  &:hover {
    transform: translateX(3px);
  }
  &.active {
    color: white;
    background: #fd3237;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-right: 0px;
    text-align: center;
  }
`

const Reveal = styled.div`
  overflow: hidden;
  background: linear-gradient(90deg, #1c2a3e 38%, #f6f6f6 32%);
  color: #344356;
  > div {
    padding: 40px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    background: linear-gradient(90deg, #1c2a3e 32%, #f6f6f6 28%);
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    background: #f6f6f6;
  }
  height: 100%;
  transition: all 0.3s ease-in-out;
`

const Row = styled.div`
  background: linear-gradient(90deg, #162232 38%, rgba(255, 255, 255, 1) 32%);
  padding-top: 100px;
  > div.inner {
    padding-bottom: 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    background: linear-gradient(90deg, #162232 32%, rgba(255, 255, 255, 1) 28%);
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    background: #162232;
    .right {
      h2,
      p {
        color: white;
      }
    }
    padding-top: 50px;
    > div.inner {
      padding-bottom: 20px;
    }
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 40px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
  }
`

const Right = styled.div`
  h2 {
    color: #223247;
    font-family: Roboto;
    font-size: 31px;
    font-weight: 300;
    line-height: 37px;
  }
  h3 {
    color: #0988bb;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 300;
    line-height: 23px;
  }
  p {
    margin-top: 20px;
    color: #223247;
    font-family: Roboto;
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-column: 1;
    grid-row: 1;
  }
`
const Left = styled.div`
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-column: 1;
    grid-row: 2;
  }
`

const StyledImg = styled(Img)`
  box-shadow: 9px 0 30px 0 rgba(66, 120, 140, 0.14);
  margin-bottom: 20px;
  border: 10px solid white;
  border-radius: 4px;
`
