import React from "react"
import styled from "styled-components"
import SvgIcon from "../../atoms/icons/svg-icon"
import ChevronLeft from "../../atoms/icons/files/chevron-left.jsx"
import ChevronRight from "../../atoms/icons/files/chevron-right.jsx"
import ArrowRight from "../../atoms/icons/files/arrow-right.jsx"
import BackgroundImage from "gatsby-background-image"
import Arrow from "./Arrow"
import { Button } from "./DesktopSlider"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const MobileSlider = ({
  setReadyToStart,
  fluid,
  mobileFluid,
  next,
  prev,
  title,
  subTitle,
  buttonLabel,
  buttonLink,
}) => {
  return (
    <Container>
      <StyledBackground
        fluid={mobileFluid.src || fluid}
        // onLoad={setReadyToStart(true)}
        // critical
      >
        <Overlay />
      </StyledBackground>
      <Content>
        <Arrow
          onClick={prev}
          direction="left"
          DirectionSvgComponent={ChevronLeft}
          mobile
        />
        <Arrow
          onClick={next}
          direction="right"
          DirectionSvgComponent={ChevronRight}
          mobile
        />
        <h2>{title}</h2>
        <p>{subTitle}</p>
        <Button to={buttonLink}>
          <div>{buttonLabel} </div>
          <SvgIcon SvgComponent={ArrowRight} hue="danger" shade="fontColor" />
        </Button>
      </Content>
    </Container>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Container = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: block;
  }
`
const Content = styled.div`
  position: relative;
  padding: 20px;
  .arrow-icon {
    margin: 0;
    position: absolute;
    top: 0;
    background: #fd3237;
    transform: translateY(-50%);
    &.right {
      right: 0;
      border-radius: 3px 0 0 3px;
    }
    &.left {
      border-radius: 0 3px 3px 0;
      left: 0;
    }
    &:hover {
      transform: translateY(-50%);
    }
  }
  h2 {
    color: #f0f1f3;
    font-family: Roboto;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 500;
    text-transform: uppercase;
  }
  p {
    color: #bac0c5;
    font-family: Roboto;
    font-size: 18px;
    margin-bottom: 10px;
  }
`

const Overlay = styled.div`
  min-height: 75vw;
`

const StyledBackground = styled.div`
  background-image: url(${({ fluid }) => fluid});
  position: relative;
  opacity: 0.99;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default MobileSlider
