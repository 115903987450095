import React, { useState, useEffect, useMemo, useRef } from "react"
import styled from "styled-components"
import { path } from "../../../lib/util"
import DesktopSlider from "./DesktopSlider"
import MobileSlider from "./MobileSlider"
import { Responsive, windowSizes } from "../../../utils/responsive"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Slider = ({ slides, autoRotate = true, duration = 7000 }) => {
  const [active, setActive] = useState(0)
  const [readyToStart, setReadyToStart] = useState(false)
  let timerId = useRef(null)

  // Go to next and set timeout to do it again
  const autoNext = () => {
    setActive((p) => (p === slides.length - 1 ? 0 : p + 1))
    setSliderTimeout()
  }
  //Go to next and reset autoNext timeout
  const next = () => {
    clearTimeout(timerId.current)
    setSliderTimeout()
    setActive((p) => (p === slides.length - 1 ? 0 : p + 1))
  }
  // Go to previous and reset autoNext timeout
  const prev = () => {
    clearTimeout(timerId.current)
    setSliderTimeout()
    setActive((p) => (p === 0 ? slides.length - 1 : p - 1))
  }
  const setSliderTimeout = () => {
    if (autoRotate) {
      timerId.current = setTimeout(autoNext, duration)
    }
  }

  useEffect(() => {
    if (autoRotate && readyToStart) {
      clearTimeout(timerId.current)
      timerId.current = setTimeout(autoNext, duration)
      return () => clearTimeout(timerId.current)
    }
  }, [autoRotate, readyToStart])

  const get = (pathArr) => path(pathArr, slides[active])

  const slideProps = useMemo(() => {
    return {
      fluid: get([
        "image",
        "imageFile",
        "localFile",
        "childImageSharp",
        "fluid",
      ]),
      mobileFluid: get([
        "mobileImage",
        "imageFile",
        "localFile",
        "childImageSharp",
        "fluid",
      ]),
      title: get(["title"]),
      subTitle: get(["subTitle"]),
      buttonLabel: get(["button", "title"]),
      next,
      prev,
      buttonLink: get(["button", "link"]),
    }
  }, [slides, active])

  return (
    <Wrap>
      <Responsive maxWidth={windowSizes.mobile}>
        <MobileSlider setReadyToStart={setReadyToStart} {...slideProps} />
      </Responsive>
      <Responsive minWidth={windowSizes.mobile}>
        <DesktopSlider setReadyToStart={setReadyToStart} {...slideProps} />
      </Responsive>
    </Wrap>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Wrap = styled.div`
  overflow: hidden;
  max-width: 100vw;
  position: relative;
  background: #1a293c;
`

export default Slider
