import React from "react"
import InfoRow from "../InfoRow"
import Inner from "../../layout/pageInner/Inner"
import styled from "styled-components"
import { LinkWrapper } from "../../../utils/linkWrapper"

import ProductList from "../../../components/Products/ProductList"
// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const SpecialRow = ({ data, products }) => {
  return (
    <InfoRow title={data.title} subTitle={data.subTitle} data={data}>
      <StyledInner>
        <ProductList
          showProductDeals
          homePageBestDeals={true}
          productList={products}
          productsPerLoad={8}
          sizesFilter={false}
        />
      </StyledInner>
    </InfoRow>
  )
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const StyledInner = styled(Inner)`
  margin-top: 50px;
  min-height: 200px;
`

const Button = styled(LinkWrapper)`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
  }

  > div {
    padding: 0 15px;
    font-size: 20px;
  }

  > span {
    padding: 8px;
    background: #da2e32;
  }
`

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

export default SpecialRow
