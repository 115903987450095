import React, { memo, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"

import ProductContext from "../context/ProductContext"

import SEO from "../components/SEO/seo"

import { Responsive, windowSizes } from "../utils/responsive"

import Layout from "../zzz/layout"
import PageInner from "../zzz/layout/pageInner"
import ValueProposition from "../zzz/atoms/valueProposition"

import Slider from "../zzz/organisms/Slider"
import SpecialRow from "../zzz/organisms/specialRow"
import LazyLoadWrapper from "../utils/lazyLoadWrapper"
import CarouselRow from "../zzz/organisms/CarouselRow"
import AboutRow from "../zzz/organisms/about"
import InfoRow from "../zzz/organisms/InfoRow"
import CtaRow from "../zzz/organisms/ctaRow"
import BedFinderRow from "../zzz/organisms/ctaRow/BedFinderRow"

const IndexPage = ({ location }) => {
  const { featuredProducts } = useContext(ProductContext)
  const data = useStaticQuery(HOME_PAGE_QUERY)

  const {
    localWpGraphQlPages: {
      seo,
      slider,
      home_page: {
        aboutUsRow,
        altCtaRow,
        ctaRow,
        postCarouselRow,
        specialsRow,
        stockRow,
        publicationsRow,
      },
    },
  } = data

  const { slides } = slider
  const { auto, timer } = slider.autoRotate

  return (
    <Layout location={location}>
      <SEO title="Home" location={location} post_id={194} seo={seo} />
      <Helmet>
        <script type="text/javascript">{` var _d_site = _d_site || '8DD253BA0E9FB222DE740ED5'; `}</script>
      </Helmet>
      <PageInner padding="0">
        <Slider slides={slides} autoRotate={auto} duration={timer} />
        <ValueProposition />
        <BedFinderAndSpecials
          products={featuredProducts}
          altCtaRow={altCtaRow}
          specialsRow={specialsRow}
        />
        <LazyLoadWrapper
          lazyComponent={<CarouselRow data={postCarouselRow} />}
        />
        <AboutRow data={aboutUsRow} />
        <LazyLoadWrapper lazyComponent={<InfoRow data={stockRow} />} />
        <LazyLoadWrapper lazyComponent={<CtaRow data={ctaRow} />} />
        <LazyLoadWrapper
          lazyComponent={<InfoRow data={publicationsRow} background="#fff" />}
        />
      </PageInner>
    </Layout>
  )
}

const BedFinderAndSpecials = memo(({ products, altCtaRow, specialsRow }) => {
  // Yeah it looks silly, but they wanted it swapped on mobile
  return (
    <>
      <Responsive maxWidth={windowSizes.tablet}>
        <LazyLoadWrapper
          placeholderHeight={"642px"}
          lazyComponent={<BedFinderRow data={altCtaRow} />}
        />
        <SpecialRow data={specialsRow} products={products} />
      </Responsive>
      <Responsive minWidth={windowSizes.tablet}>
        <SpecialRow data={specialsRow} products={products} />
        <LazyLoadWrapper
          placeholderHeight={"642px"}
          lazyComponent={<BedFinderRow data={altCtaRow} />}
        />
      </Responsive>
    </>
  )
})

export default IndexPage

const HOME_PAGE_QUERY = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 194 }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      slider {
        autoRotate {
          auto
          timer
        }
        slides {
          image {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1400, toFormat: JPG) {
                    src
                  }
                }
              }
            }
          }
          mobileImage {
            sourceUrl
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 85, maxWidth: 480, toFormat: JPG) {
                    src
                  }
                }
              }
            }
          }
          title
          subTitle
          button {
            title
            link
          }
        }
      }
      home_page {
        specialsRow {
          subTitle
          title
        }
        aboutUsRow {
          body
          subTitle
          title
          tabs {
            title
            content
          }
          featuredImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 60, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        altCtaRow {
          body
          title
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 50, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ctaRow {
          body
          title
          backgroundImage {
            sourceUrl
            mediaItemId
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          buttons {
            title {
              link
              title
            }
          }
        }
        postCarouselRow {
          subTitle
          title
        }
        publicationsRow {
          subTitle
          title
          logos {
            link
            image {
              sourceUrl
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    fixed(quality: 50, width: 150) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        stockRow {
          subTitle
          title
          logos {
            image {
              sourceUrl
              mediaItemId
              imageFile {
                localFile {
                  childImageSharp {
                    fixed(quality: 50, width: 150) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            link
          }
        }
      }
    }
  }
`
